// Ids in this file should match ids in scoringData.js

export const mhqSchoolFlows = [
    {
        id: 1,
        handle: 'school_quality_teaming',
        flowId: 12,
        name: 'Teaming',
    },
    {
        id: 2,
        handle: 'school_quality_needsassessment',
        flowId: 15,
        name: 'Needs Assessment/ Resource Mapping',
    },
    {
        id: 3,
        handle: 'school_quality_screening',
        flowId: 1,
        name: 'Screening',
    },
    {
        id: 4,
        handle: 'school_quality_MHpromotion',
        flowId: 16,
        name: 'Mental Health Promotion Services & Supports',
    },
    {
        id: 5,
        handle: 'school_quality_earlyintervention',
        flowId: 17,
        name: 'Early Intervention and Treatment Services & Supports',
    },
    {
        id: 6,
        handle: 'school_quality_funding',
        flowId: 18,
        name: 'Funding and Sustainability',
    },
    {
        id: 7,
        handle: 'school_quality_impact',
        flowId: 19,
        name: 'Impact',
    },
];

export const mhqDistrictFlows = [
    {
        id: 1,
        handle: 'district_quality_teaming',
        flowId: 22,
        name: 'Teaming',
    },
    {
        id: 2,
        handle: 'district_quality_needsassessment',
        flowId: 23,
        name: 'Needs Assessment/ Resource Mapping',
    },
    {
        id: 3,
        handle: 'district_quality_screening',
        flowId: 20,
        name: 'Screening',
    },
    {
        id: 4,
        handle: 'district_quality_MHpromotion',
        flowId: 24,
        name: 'Mental Health Promotion Services & Supports',
    },
    {
        id: 5,
        handle: 'district_quality_earlyintervention',
        flowId: 25,
        name: 'Early Intervention and Treatment Services & Supports',
    },
    {
        id: 6,
        handle: 'district_quality_funding',
        flowId: 26,
        name: 'Funding and Sustainability',
    },
    {
        id: 7,
        handle: 'district_quality_impact',
        flowId: 27,
        name: 'Impact',
    },
];

export const mhqEntityFlows = [
    {
        id: 1,
        handle: 'entity_quality_teaming',
        flowId: 59,
        name: 'Teaming',
    },
    {
        id: 2,
        handle: 'entity_quality_needsassessment',
        flowId: 55,
        name: 'Needs Assessment/ Resource Mapping',
    },
    {
        id: 3,
        handle: 'entity_quality_screening',
        flowId: 57,
        name: 'Screening',
    },
    {
        id: 4,
        handle: 'entity_quality_MHpromotion',
        flowId: 53,
        name: 'Mental Health Promotion Services & Supports',
    },
    {
        id: 5,
        handle: 'entity_quality_earlyintervention',
        flowId: 47,
        name: 'Early Intervention and Treatment Services & Supports',
    },
    {
        id: 6,
        handle: 'entity_quality_funding',
        flowId: 49,
        name: 'Funding and Sustainability',
    },
    {
        id: 7,
        handle: 'entity_quality_impact',
        flowId: 51,
        name: 'Impact',
    },
];

export const trsFlows = [
    {
        id: 8,
        handle: 'TRS_wssplanning',
        flowId: 6,
        name: 'Whole School Safety Planning',
    },
    {
        id: 9,
        handle: 'TRS_wsprevention',
        flowId: 5,
        name: 'Whole School Prevention Planning',
    },
    {
        id: 10,
        handle: 'TRS_wstrauma',
        flowId: 7,
        name: 'Whole School Trauma Programming',
    },
    {
        id: 11,
        handle: 'TRS_classroomstrat',
        flowId: 8,
        name: 'Classroom Strategies',
    },
    {
        id: 12,
        handle: 'TRS_prevention_earlyintervention',
        flowId: 4,
        name: 'Prevention/ Early Intervention Trauma Programming',
    },
    {
        id: 13,
        handle: 'TRS_tiprogramming',
        flowId: 9,
        name: 'Targeted Trauma-Informed Programming',
    },
    {
        id: 14,
        handle: 'TRS_staffselfcare',
        flowId: 10,
        name: 'Staff Self Care',
    },
    {
        id: 15,
        handle: 'TRS_familyeng',
        flowId: 11,
        name: 'Family and Community Engagement',
    },
];

export const mhProfileSchool = [
    {
        id: 1,
        handle: 'SMH_profile_school_domain1',
        flowId: 31,
        name: 'Students Served and Data Systems',
    },
    {
        id: 2,
        handle: 'SMH_profile_school_domain2',
        flowId: 32,
        name: 'Staffing',
    },
    {
        id: 3,
        handle: 'SMH_profile_school_domain3',
        flowId: 42,
        name: 'Services and Supports',
    },
];

export const mhProfileDistrict = [
    {
        id: 1,
        handle: 'SMH_profile_district_domain1',
        flowId: 43,
        name: 'Students Served and Data Systems',
    },
    {
        id: 2,
        handle: 'SMH_profile_district_domain2',
        flowId: 44,
        name: 'Staffing',
    },
    {
        id: 3,
        handle: 'SMH_profile_district_domain3',
        flowId: 45,
        name: 'Services and Supports',
    },
];

export const mhProfileEntity = [
    {
        id: 1,
        handle: 'SMH_profile_entity_domain_1',
        flowId: 61,
        name: 'Students Served and Data Systems',
    },
    {
        id: 2,
        handle: 'SMH_profile_entity_domain_2',
        flowId: 62,
        name: 'Staffing',
    },
    {
        id: 3,
        handle: 'SMH_profile_entity_domain_3',
        flowId: 63,
        name: 'Services and Supports',
    },
];

export const mhProfileState = [
    {
        id: 1,
        handle: 'SMH_profile_state_domain_1',
        flowId: 34,
        name: 'State/Territory Agencies & Organizations',
    },
    {
        id: 2,
        handle: 'SMH_profile_state_domain_2',
        flowId: 35,
        name: 'State/Territory Level Leadership',
    },
    {
        id: 3,
        handle: 'SMH_profile_state_domain_3',
        flowId: 36,
        name: 'Policy',
    },
    {
        id: 4,
        handle: 'SMH_profile_state_domain_4',
        flowId: 37,
        name: 'Funding',
    },
    {
        id: 5,
        handle: 'SMH_profile_state_domain_5',
        flowId: 38,
        name: 'Services and Supports',
    },
    {
        id: 6,
        handle: 'SMH_profile_state_domain_6',
        flowId: 39,
        name: 'Innovations and Emerging Areas',
    },
    {
        id: 7,
        handle: 'SMH_profile_state_domain_7',
        flowId: 40,
        name: 'Staffing and Community Partnered Behavioral Health',
    },
    {
        id: 8,
        handle: 'SMH_profile_state_domain_8',
        flowId: 41,
        name: 'Barriers and Resources',
    },
];

export const mhqPreschoolFlows = [
    {
        id: 1,
        handle: 'pre_family_partnerships',
        flowId: 84,
        name: 'Family Partnerships',
    },
    {
        id: 2,
        handle: 'pre_services_and_supports',
        flowId: 85,
        name: 'Services and Supports',
    },
    {
        id: 3,
        handle: 'pre_teaming',
        flowId: 86,
        name: 'Teaming',
    },
    {
        id: 4,
        handle: 'pre_mental_health_promotion',
        flowId: 87,
        name: 'Mental Health Promotion',
    },
    {
        id: 5,
        handle: 'pre_kindergarten_transition',
        flowId: 88,
        name: 'Kindergarten Transition',
    },
    {
        id: 6,
        handle: 'pre_mental_health_screening',
        flowId: 89,
        name: 'Mental Health Screening',
    },
];

// OWBI

export const owbiSchoolFlows = [
    {
        id: 9,
        handle: 'school_owbi_workclimate',
        flowId: 90,
        name: 'Work Climate and Environment',
    },
    {
        id: 10,
        handle: 'school_owbi_input',
        flowId: 91,
        name: 'Input, Flexibility, and Autonomy',
    },
    {
        id: 11,
        handle: 'school_owbi_development',
        flowId: 92,
        name: 'Professional Development and Recognition',
    },
    {
        id: 12,
        handle: 'school_owbi_org_support',
        flowId: 93,
        name: 'Organizational and Supervisory Support',
    },
    {
        id: 13,
        handle: 'school_owbi_selfcare',
        flowId: 94,
        name: 'Self-Care',
    },
    {
        id: 14,
        handle: 'school_owbi_deia',
        flowId: 95,
        name: 'Diversity, Equity, Inclusion, Access (DEIA)',
    },
    {
        id: 15,
        handle: 'school_owbi_purpose',
        flowId: 96,
        name: 'Purpose and Meaningfulness',
    },
    {
        id: 16,
        handle: 'school_owbi_qol',
        flowId: 97,
        name: 'Professional Quality of Life',
    },
];

export const owbiDistrictFlows = [
    {
        id: 9,
        handle: 'district_owbi_workclimate',
        flowId: 106,
        name: 'Work Climate and Environment',
    },
    {
        id: 10,
        handle: 'district_owbi_input',
        flowId: 107,
        name: 'Input, Flexibility, and Autonomy',
    },
    {
        id: 11,
        handle: 'district_owbi_development',
        flowId: 108,
        name: 'Professional Development and Recognition',
    },
    {
        id: 12,
        handle: 'district_owbi_org_support',
        flowId: 109,
        name: 'Organizational and Supervisory Support',
    },
    {
        id: 13,
        handle: 'district_owbi_selfcare',
        flowId: 110,
        name: 'Self-Care',
    },
    {
        id: 14,
        handle: 'district_owbi_deia',
        flowId: 111,
        name: 'Diversity, Equity, Inclusion, Access (DEIA)',
    },
    {
        id: 15,
        handle: 'district_owbi_purpose',
        flowId: 112,
        name: 'Purpose and Meaningfulness',
    },
    {
        id: 16,
        handle: 'district_owbi_qol',
        flowId: 113,
        name: 'Professional Quality of Life',
    },
];

export const owbiEntityFlows = [
    {
        id: 9,
        handle: 'entity_owbi_workclimate',
        flowId: 98,
        name: 'Work Climate and Environment',
    },
    {
        id: 10,
        handle: 'entity_owbi_input',
        flowId: 99,
        name: 'Input, Flexibility, and Autonomy',
    },
    {
        id: 11,
        handle: 'entity_owbi_development',
        flowId: 100,
        name: 'Professional Development and Recognition',
    },
    {
        id: 12,
        handle: 'entity_owbi_org_support',
        flowId: 101,
        name: 'Organizational and Supervisory Support',
    },
    {
        id: 13,
        handle: 'entity_owbi_selfcare',
        flowId: 102,
        name: 'Self-Care',
    },
    {
        id: 14,
        handle: 'entity_owbi_deia',
        flowId: 103,
        name: 'Diversity, Equity, Inclusion, Access (DEIA)',
    },
    {
        id: 15,
        handle: 'entity_owbi_purpose',
        flowId: 104,
        name: 'Purpose and Meaningfulness',
    },
    {
        id: 16,
        handle: 'entity_owbi_qol',
        flowId: 105,
        name: 'Professional Quality of Life',
    },
];

export const careSchoolFlows = [
    {
        id: 10,
        handle: 'school_care_teaming',
        flowId: 94,
        name: 'Teaming and Collaboration',
    },
    {
        id: 11,
        handle: 'school_care_climate',
        flowId: 93,
        name: 'School Climate',
    },
    {
        id: 12,
        handle: 'school_care_discipline',
        flowId: 91,
        name: 'School Discipline',
    },
    {
        id: 13,
        handle: 'school_care_curricula',
        flowId: 115,
        name: 'Curricula and Teaching Practices',
    },
    {
        id: 14,
        handle: 'school_care_prof_dev',
        flowId: 92,
        name: 'Professional Development',
    },
];

export const careDistrictFlows = [
    {
        id: 10,
        handle: 'district_care_teaming',
        flowId: 99,
        name: 'Teaming and Collaboration',
    },
    {
        id: 11,
        handle: 'district_care_climate',
        flowId: 98,
        name: 'School Climate',
    },
    {
        id: 12,
        handle: 'district_care_discipline',
        flowId: 96,
        name: 'School Discipline',
    },
    {
        id: 13,
        handle: 'district_care_curricula',
        flowId: 95,
        name: 'Curricula and Teaching Practices',
    },
    {
        id: 14,
        handle: 'district_care_prof_dev',
        flowId: 97,
        name: 'Professional Development',
    },
];

export const careEntityFlows = [
    {
        id: 10,
        handle: 'entity_care_teaming',
        flowId: 124,
        name: 'Teaming and Collaboration',
    },
    {
        id: 11,
        handle: 'entity_care_climate',
        flowId: 125,
        name: 'School Climate',
    },
    {
        id: 12,
        handle: 'entity_care_discipline',
        flowId: 126,
        name: 'School Discipline',
    },
    {
        id: 13,
        handle: 'entity_care_curricula',
        flowId: 127,
        name: 'Curricula and Teaching Practices',
    },
    {
        id: 14,
        handle: 'entity_care_prof_dev',
        flowId: 128,
        name: 'Professional Development',
    },
];

export const familyEngagementSchoolFlows = [
    {
        id: 1,
        handle: 'school_fe_activities',
        flowId: 156,
        name: 'Engagement Activities',
    },
    {
        id: 2,
        handle: 'school_fe_caregiver',
        flowId: 157,
        name: 'Caregiver Expectations',
    },
];

export const familyEngagementDistrictFlows = [
    {
        id: 1,
        handle: 'district_fe_activities',
        flowId: 158,
        name: 'Engagement Activities',
    },
    {
        id: 2,
        handle: 'district_fe_caregiver',
        flowId: 159,
        name: 'Caregiver Expectations',
    },
];

export const familyEngagementEntityFlows = [
    {
        id: 1,
        handle: 'entity_fe_activities',
        flowId: 160,
        name: 'Engagement Activities',
    },
    {
        id: 2,
        handle: 'entity_fe_caregiver',
        flowId: 161,
        name: 'Caregiver Expectations',
    },
];
