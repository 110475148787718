import {
    trsFlows,
    mhqSchoolFlows,
    mhqDistrictFlows,
    mhqEntityFlows,
    mhqPreschoolFlows,
    owbiSchoolFlows,
    owbiDistrictFlows,
    owbiEntityFlows,
    careSchoolFlows,
    careDistrictFlows,
    careEntityFlows,
    familyEngagementSchoolFlows,
    familyEngagementDistrictFlows,
    familyEngagementEntityFlows,
} from './flowData.js';

import _find from 'lodash/find';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';
import _uniq from 'lodash/uniq';
import _flatten from 'lodash/flatten';

const getFlowType = flowType => {
    if (flowType === 'trs') {
        return trsFlows;
    }
    if (flowType === 'mhqSchool') {
        return mhqSchoolFlows;
    }
    if (flowType === 'mhqPreschool') {
        return mhqPreschoolFlows;
    }
    if (flowType === 'mhqDistrict') {
        return mhqDistrictFlows;
    }
    if (flowType === 'mhqEntity') {
        return mhqEntityFlows;
    }
    if (flowType === 'owbiSchool') {
        return owbiSchoolFlows;
    }
    if (flowType === 'owbiDistrict') {
        return owbiDistrictFlows;
    }
    if (flowType === 'owbiEntity') {
        return owbiEntityFlows;
    }
    if (flowType === 'careSchool') {
        return careSchoolFlows;
    }
    if (flowType === 'careDistrict') {
        return careDistrictFlows;
    }
    if (flowType === 'careEntity') {
        return careEntityFlows;
    }
    if (flowType === 'familyEngagementSchool') {
        return familyEngagementSchoolFlows;
    }
    if (flowType === 'familyEngagementDistrict') {
        return familyEngagementDistrictFlows;
    }
    if (flowType === 'familyEngagementEntity') {
        return familyEngagementEntityFlows;
    }
};

export const getDomainNames = (domains, flowType) => {
    if (domains) {
        const domainNames = domains.map(id => _find(getFlowType(flowType), { id }).handle);
        return domainNames;
    }
};

export const getDomainsWithData = (startDate, endDate, shapeFlowData) => {
    // Returns an object with data associated
    const domainsWithData = _pickBy(shapeFlowData, d => d.data && d.data.length > 0);

    const domains = Object.keys(domainsWithData);

    return domains;
};

export const getDomainOptions = (startDate, endDate, shapeFlowData, flows, isAggregateReport, flowType) => {
    if (isAggregateReport) {
        return getFlowType(flowType);
    }

    const domainsWithData = getDomainsWithData(startDate, endDate, shapeFlowData);

    return domainsWithData.map(domain => flows.find(flow => flow.handle === domain));
};

export const getNewToSchoolOptions = () => {
    return [
        {
            id: 1,
            handle: 'yes',
            name: '3 years or less',
            value: 1,
        },
        {
            id: 0,
            handle: 'no',
            name: 'More than 3 years',
            value: 0,
        },
        {
            id: 888,
            handle: 'prefer',
            name: 'Prefer Not to Respond',
            value: 888,
        },
    ];
};

export const getTeacherOptions = () => {
    return [
        {
            id: 1,
            handle: 'yes',
            name: 'Teacher',
            value: 1,
        },
        {
            id: 0,
            handle: 'no',
            name: 'Other School Staff',
            value: 0,
        },
        {
            id: 888,
            handle: 'prefer',
            name: 'Prefer Not to Respond',
            value: 888,
        },
    ];
};

const getAllUsers = (domains, domainValues, flowType, shapeFlowData) => {
    if (domains) {
        // Get domain names based on domain form field value
        const selectedDomainNames = getDomainNames(domainValues, flowType);

        // Get actual flow data based on selected domains
        const shapeFlowDataByDomain = _pick(shapeFlowData, selectedDomainNames);

        // Get all users that have completed flows. Flatten all data into one array.
        const allUsersFlows = _map(shapeFlowDataByDomain, 'data');
        const allUsers = _flatten(allUsersFlows);

        return allUsers;
    }
};

export const getUniqueUsers = (domains, domainValues, flowType, shapeFlowData) => {
    const unique = _uniqBy(getAllUsers(domains, domainValues, flowType, shapeFlowData), 'pawn_id');
    return unique;
};

export const getUniqueAnonUsers = (domains, shapeFlowData) => {
    if (domains) {
        const domainNames = domains.map(d => d.handle);
        const domainData = _pick(shapeFlowData, domainNames);
        const allUsers = Object.values(domainData).flatMap(domain => domain.map(obj => obj.pawnId));
        const unique = _uniq(allUsers);
        return unique;
    }
    return [];
};
