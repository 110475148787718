import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './style.css';

const mapStateToProps = (state, props) => ({
    legacyUser: state.app.shape.legacyUser,
});

const currentYear = new Date().getFullYear();

export default connect(mapStateToProps)(
    class Footer extends React.Component {
        static propTypes = {
            legacyUser: PropTypes.bool,
        };

        render() {
            return (
                <div className={style.footer}>
                    <div className={style.footerUpper}>
                        <div className="container">
                            {this.props.legacyUser ? (
                                <div className={style.legacyLink}>
                                    <a
                                        href="https://legacy.theshapesystem.com/cv"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        style={{ marginRight: '0.25em' }}
                                    >
                                        Click here{' '}
                                    </a>

                                    {' to view your legacy SHAPE data.'}
                                </div>
                            ) : (
                                ''
                            )}
                            <div className={style.footerContent}>
                                <div className={style.footerImage} />
                                <div className={style.footerText}>
                                    National Center for School Mental Health <br />
                                    University of Maryland School of Medicine <br />
                                    737 West Lombard Street, 4th Floor <br />
                                    Baltimore, MD 21201
                                </div>
                                <div className={style.footerText}>
                                    P: (410) 706-0980 <br />
                                    F: (410) 706-0984 <br />
                                    E: ncsmh@som.umaryland.edu
                                </div>
                                <div className={style.footerLinkContainer}>
                                    <div className={style.footerLinks}>
                                        <a href="https://theshapesystem.com/about-us">About Us</a>
                                    </div>
                                    <div className={style.footerLinks}>
                                        <a href="https://theshapesystem.com/faq">FAQ</a>
                                    </div>
                                    <div className={style.footerLinks}>
                                        <a href="https://theshapesystem.com/contact">Contact</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.footerLower}>
                        <div className="container">
                            <div className={style.footerLowerContent}>
                                <div style={{ marginRight: '0.5em' }}>
                                    Website and software developed by{' '}
                                    <a
                                        style={{
                                            margin: '0',
                                            textDecoration: 'underline',
                                            backgroundColor: '#308b85',
                                        }}
                                        href="https://www.3cisd.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        3C Institute
                                    </a>
                                    .
                                </div>
                                <div style={{ marginRight: '0.5em' }}>
                                    ©{currentYear} University of Maryland. All rights reserved.
                                </div>
                                <div className={style.lowerFooterLinks}>
                                    <span style={{ color: 'black' }}>|</span>
                                    <a href="https://www.3cisd.com/#ccc-lets-talk">Contact Us</a>
                                    <span style={{ color: 'black' }}>|</span>
                                    <a href="https://theshapesystem.com/privacy-policy/">Privacy Policy</a>
                                    <span style={{ color: 'black' }}>|</span>
                                    <a href="http://www.3cisd.com/terms-of-use">Terms of Use</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
);
